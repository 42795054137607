import { useEffect, useRef, useState } from 'react';

type Props = {
  callbackOnAppearance: () => void;
  root?: TSFix;
  rootMargin?: string;
};

const Observer = ({ callbackOnAppearance, root, rootMargin }: Props) => {
  const observeElement = useRef<HTMLDivElement>(null);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  // Initialize the intersection observer instance
  useEffect(() => {
    const observe = (entries: TSFix[]) => {
      // IntersectionObserver calls the callback both when the target comes on screen and leaves screen.
      // We only need to call listen to when it appears on the screen.
      const target = entries[0];
      if (target && !target.isIntersecting) return;

      callbackOnAppearance();
    };

    const initializeObserver = () => {
      const io = new IntersectionObserver(observe, {
        root: root ? root.current : null,
        threshold: 0,
        rootMargin,
      });

      setObserver(io);
    };

    initializeObserver();
  }, [callbackOnAppearance, root, rootMargin]);

  // When the element is rendered, attach the observer. When it goes away, unobserve it.
  useEffect(() => {
    const element = observeElement && observeElement.current;
    if (element && observer) {
      observer.observe(element);
    }

    return () => {
      if (observer) observer.unobserve(element as HTMLDivElement);
    };
  }, [observer, observeElement]);

  return <div ref={observeElement} />;
};

export default Observer;
