import Observer from './Observer';

import type { Pagination } from 'types';

type Props = {
  additionalCheck?: boolean;
  fetcherState: 'idle' | 'loading' | 'submitting';
  load: () => void;
  pagination?: Pagination;
  root?: TSFix;
  rootMargin?: string;
};

const InfiniteScroll = ({ additionalCheck = true, fetcherState, load, pagination, root, rootMargin }: Props) => {
  return additionalCheck && pagination && !pagination.last_page && fetcherState !== 'loading' ? (
    <Observer callbackOnAppearance={load} root={root} rootMargin={rootMargin} />
  ) : null;
};

export default InfiniteScroll;
